import {alpha, Box, Button, Grid, styled} from '@mui/material'
import {graphql} from 'gatsby'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import * as React from 'react'
import remark from 'remark'
import remarkGFM from 'remark-gfm'
import remarkHTML from 'remark-html'
import {Headline, Link, RouteLink, Section, SidebarGrid} from '../components'
import {CookieConsentProvider} from '../context'
import {PageLayout} from '../layout'
import {pxToRem} from '../theme'

const StyledDownloadButton = styled(Button)(({theme}) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: pxToRem(13),
  fontFamily: '"Exo 2", Helvetica, Arial, sans-serif',
  color: theme.palette.common.white,
  borderRadius: 8,
  padding: theme.spacing(0.35, 3),
}))

const StyledContentContainer = styled(Box)(({theme}) => ({
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const StyledRouteLink = styled(RouteLink)(({theme}) => ({
  display: 'block',
  width: '100%',
  color:
    theme.palette.mode === 'dark'
      ? alpha(theme.palette.common.white, 0.7)
      : theme.palette.grey[500],
}))

// Note: this needs to be a JS file to support gatsby-node's page generator
export default function LegalDocumentTemplate({data, pageContext}) {
  const {t} = useTranslation()
  const {language} = useI18next()
  const {markdownRemark, allMarkdownRemark} = data
  const {frontmatter} = markdownRemark

  const toHTML = (value) =>
    remark().use(remarkHTML).use(remarkGFM).processSync(value).toString()

  const getFormattedSlug = (slug) => {
    let updatedSlug = slug

    if (updatedSlug.startsWith('/legal-documents')) {
      const [pageType, lang, subPage] = updatedSlug.split('/').filter(Boolean)

      updatedSlug = `/${lang}/${pageType}/${subPage}`
    } else {
      updatedSlug = `/${language}${updatedSlug}`
    }

    if (!updatedSlug.endsWith('/')) {
      return `${updatedSlug}/`
    }

    return updatedSlug
  }

  return (
    <PageLayout
      seo={{
        title: frontmatter.title,
        siteName: data.site.siteMetadata.title,
        lang: language,
      }}
      footerProps={{
        phoneNumber: data.localizedSettings?.frontmatter?.phoneNumber,
      }}
      subpage
    >
      <CookieConsentProvider
        language={language}
        title={t('cookie.consent.title')}
        body={t('cookie.consent.body')}
        acceptLabel={t('cookie.consent.agree')}
        rejectLabel={t('cookie.consent.decline')}
        linkLabel={t('cookie.consent.link')}
      />

      <Section>
        <Grid container direction="row">
          <SidebarGrid md={3} xs={12}>
            {!pageContext.hideSidebar && (
              <Grid
                container
                sx={{
                  display: 'grid',
                  gridAutoFlow: 'row',
                  gap: (theme) => theme.spacing(2),
                  paddingRight: (theme) => theme.spacing(2),
                }}
              >
                {allMarkdownRemark.edges
                  .filter(({node}) =>
                    node.fields.slug.includes(`/${language}/`),
                  )
                  .map(({node}) => (
                    <Grid
                      item
                      key={node.fields.slug}
                      sx={{padding: `0 !important`}}
                    >
                      <StyledRouteLink
                        navLink
                        to={getFormattedSlug(node.fields.slug)}
                        hrefLang={language}
                      >
                        {node.frontmatter.title}
                      </StyledRouteLink>
                    </Grid>
                  ))}
              </Grid>
            )}
          </SidebarGrid>

          <Grid item md={9} xs={12}>
            <Headline color="primary" variant="h2" component="h1">
              {frontmatter.title}
            </Headline>

            {frontmatter.pdf && frontmatter.pdf.publicURL && (
              <Link
                href={frontmatter.pdf.publicURL}
                rel="noopener noreferrer"
                target="_blank"
                sx={{
                  display: 'inline-block',
                  margin: (theme) => theme.spacing(2, 0, 0),
                  padding: 0,
                  ':hover': {
                    textDecoration: 'none',
                  },
                }}
              >
                <StyledDownloadButton
                  color="primary"
                  variant="contained"
                  disableElevation
                  component="span"
                >
                  {t('legal.download')}
                </StyledDownloadButton>
              </Link>
            )}

            <StyledContentContainer
              dangerouslySetInnerHTML={{__html: toHTML(frontmatter.content)}}
            />
          </Grid>
        </Grid>
      </Section>
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query($slug: String!, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: {frontmatter: {templateKey: {eq: "legal-documents"}}}
      sort: {fields: fields___slug, order: ASC}
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    markdownRemark(fields: {slug: {eq: $slug}}) {
      frontmatter {
        title
        content
        pdf {
          publicURL
        }
      }
    }
    localizedSettings: markdownRemark(
      frontmatter: {
        templateKey: {eq: "localized-settings"}
        language: {eq: $language}
      }
    ) {
      frontmatter {
        phoneNumber
      }
    }
  }
`
